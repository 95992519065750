<template>
  <Navbar />
  <div class="container jumbotron mt-3">
    <h1 class="text-center mb-5">Soutenir le projet en faisant un don</h1>
    <div class="row mt-3">
      <div class="col-12 col-md-4 donation-image">
        <img
          src="@/assets/img/donation.png"
          class="img-fluid mx-auto mr-2"
          alt=""
        />
      </div>
      <div class="col-md-8 order-md-1">
        <h4 class="mb-3 font-weight-bold">
          {{ $t("PaymentPage.PAYMENT_METHOD_DON") }}
        </h4>

        <div class="d-block my-3 mx-auto">
          <div class="custom-control custom-radio">
            <input
              id="credit"
              name="paymentMethod"
              type="radio"
              class="custom-control-input"
              checked=""
              required=""
              @change="onPaymentMethodChange"
            />
            <label class="custom-control-label" for="credit"
              >Mobile Money</label
            >
          </div>
          <div class="custom-control custom-radio">
            <input
              id="debit"
              name="paymentMethod"
              type="radio"
              class="custom-control-input"
              required=""
              @change="onPaymentMethodChange"
            />
            <label class="custom-control-label" for="debit"
              >Visa/Mastercard</label
            >
          </div>
        </div>

        <div v-if="isCreditCardPayment">
          <form
            class="needs-validation"
            novalidate=""
            @submit.prevent="makeDonation"
          >
            <hr class="mb-4" />
            <h4 class="mb-3 font-weight-bold">
              {{ $t("PaymentPage.BILLING_INFORMATION") }}
            </h4>
            <div class="row">
              <div class="col-md-5 mb-3">
                <label for="adresse"
                  >Adress<span style="color: red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="adresse"
                  placeholder=""
                  required=""
                  v-model="customer_address"
                  :class="getAdressClass"
                />
                <!-- <div class="invalid-feedback">
                  Adress
                </div> -->
              </div>
              <div class="col-md-4 mb-3">
                <label for="telephone"
                  >Phone number<span style="color: red">*</span></label
                >
                <input
                  type="tel"
                  class="form-control"
                  id="telephone"
                  placeholder=""
                  required=""
                  :class="getPhoneClass"
                  v-model="customer_phone_number"
                />
                <!-- <div class="invalid-feedback">
                  Phone number
                </div> -->
              </div>
              <div class="col-md-3 mb-3">
                <label for="ville">City<span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="ville"
                  placeholder=""
                  required=""
                  v-model="customer_city"
                  :class="getCityClass"
                />
                <!-- <div class="invalid-feedback">
                  City
                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 mb-3">
                <label for="country"
                  >Country<span style="color: red">*</span></label
                >
                <select
                  class="custom-select d-block w-100"
                  id="country"
                  required=""
                  v-model="customer_country"
                >
                  <option v-for="c in countries" :key="c.name" :value="c.code">
                    {{ c.name }}
                  </option>
                </select>
                <!-- <div class="invalid-feedback">
                  Please select a valid country.
                </div> -->
              </div>
              <div class="col-md-4 mb-3">
                <label for="zip">State<span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  placeholder=""
                  required=""
                  v-model="customer_state"
                  :class="getStateClass"
                />
                <!-- <div class="invalid-feedback">
                  State required
                </div>
                <div class="invalid-feedback">
                  Please provide a valid state.
                </div> -->
              </div>
              <div class="col-md-3 mb-3">
                <label for="zip">Zip<span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="zip"
                  placeholder=""
                  required=""
                  v-model="customer_zip_code"
                  :class="getZipClass"
                />
                <!-- <div class="invalid-feedback">
                  Zip code required.
                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 mb-3">
                <label for="lastname"
                  >Prenom<span style="color: red">*</span></label
                >
                <input
                  class="d-block w-100 form-control"
                  id="lastname"
                  required=""
                  v-model="customer_surname"
                  type="text"
                  :class="getSurnameClass"
                />

                <!-- <div class="invalid-feedback">
                  Please select a valid country.
                </div> -->
              </div>
              <div class="col-md-4 mb-3">
                <label for="nom">Nom<span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="nom"
                  placeholder=""
                  required=""
                  v-model="customer_name"
                  :class="getNameClass"
                />
                <!-- <div class="invalid-feedback">
                  State required
                </div>
                <div class="invalid-feedback">
                  Please provide a valid state.
                </div> -->
              </div>
              <div class="col-md-3 mb-3">
                <label for="email"
                  >Email<span style="color: red">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder=""
                  required=""
                  v-model="customer_email"
                  :class="getEmailClass"
                />
                <!-- <div class="invalid-feedback">
                  Zip code required.
                </div> -->
              </div>
            </div>

            <br />
            <div class="row">
              <div
                class="
                  col-12
                  mx-auto
                  form-inline
                  p-0
                  d-flex
                  justify-content-start
                "
              >
                <div class="col-4 ml-0">
                  <label
                    for="currency-XAF"
                    class="ml-0 d-flex justify-content-start"
                    >Currency <span class="text-danger">*</span></label
                  >
                </div>

                <div class="col-4">
                  <input
                    type="radio"
                    name="currency"
                    class="form-control"
                    value="XOF"
                    v-model="currency"
                  /><span class="ml-1">FCFA CEDEAO</span>
                </div>

                <div class="col-4">
                  <input
                    type="radio"
                    name="currency"
                    class="form-control"
                    v-model="currency"
                    value="XAF"
                  /><span class="ml-1">FCFA CEMAC</span>
                </div>
              </div>
            </div>

            <br />
            <div class="row">
              <div class="col-12 col-5 mx-auto">
                <label for="amount"
                  >Amount <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  v-model="amount"
                  class="form-control"
                  id="amount"
                  min="100"
                  :class="getAmountClass"
                />

                <small
                  class="invalid-feedback"
                  >{{$t("DonationPage.INVALID_AMOUNT")}}</small
                >
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12">
                <label for="comments">Comments <span></span></label>
                <br />
                <textarea
                  :placeholder="commentPlaceholder"
                  v-model="comments"
                  class="form-control"
                  id="comments"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 mx-auto mt-5">
                <button type="submit" class="btn btn-primary btn-lg">
                  <span class="fas fa-donate"></span> Donate
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="loading"
                    :disabled="isSendable"
                  ></span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- -----------------------------------------------Mobile payment -------------------------- -->

        <div v-else>
          <form
            class="needs-validation"
            novalidate=""
            @submit.prevent="makeDonation"
          >
            <hr class="mb-4" />
            <h4 class="mb-3 font-weight-bold">
              {{ $t("PaymentPage.BILLING_INFORMATION") }}
            </h4>
            <div class="row">
              <div class="col-12">
                <label for="phone"
                  >Phone <span class="text-danger">*</span></label
                >
                <input
                  type="tel"
                  v-model="customer_phone_number"
                  id="phone"
                  :class="getPhoneClass"
                  class="form-control"
                />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12 mx-auto form-inline">
                <div class="col-4 ml-0 p-0 d-flex justify-content-start">
                  <label for="currency-XAF" class="ml-0"
                    >Currency <span class="text-danger">*</span></label
                  >
                </div>

                <div class="col-4">
                  <input
                    type="radio"
                    name="currency"
                    class="form-control"
                    value="XOF"
                    v-model="currency"
                  /><span class="ml-1">FCFA CEDEAO</span>
                </div>

                <div class="col-4">
                  <input
                    type="radio"
                    class="form-control"
                    v-model="currency"
                    value="XAF"
                    name="currency"
                  /><span class="ml-1">FCFA CEMAC</span>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12 mx-auto">
                <label for="amount"
                  >Amount <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  v-model="amount"
                  class="form-control"
                  id="amount"
                  min="100"
                  :class="getAmountClass"
                />
                <small
                  class="invalid-feedback"
                  >{{$t("DonationPage.INVALID_AMOUNT")}}</small
                >
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12">
                <label for="comments">Comments <span></span></label>
                <br />
                <textarea
                  :placeholder="commentPlaceholder"
                  v-model="comments"
                  class="form-control"
                  id="comments"
                  cols="30"
                  rows="2"
                ></textarea>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 mx-auto mt-5">
                <button
                  :disabled="isSendable"
                  class="btn btn-primary btn-lg"
                  type="submit"
                >
                  <span class="fas fa-donate"></span> Donate
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="loading"
                  ></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <DashboardFooter />
</template>
<script>
import Navbar from "@/components/layouts/Navbar.vue";
import numeral from "numeral";
import DashboardFooter from "@/components/layouts/DashboardFooter";
import countries from "@/store/countries.json";
import CreateStore from "@/store/index.js";
export default {
  components: {
    Navbar,
    DashboardFooter,
  },
  data() {
    return {
      loading: false,
      isPromoCodeChecking: false,
      isCouponValide: false,
      paymentLink: "",
      course: {
        id: 0,
        title: "",
        description: "",
        price: 0,
        currency: "",
        coursePicture: {
          fullSize: "",
          thumbnail: "",
          mediumSquareCrop: "",
        },
        teacher: {
          firstName: "",
          lastName: "",
          profilePicture: {
            mediumSquareCrop: "",
            fullSize: "",
            thumbnail: "",
          },
        },
      },
      newPrice: null,
      retriedToLoad: false,
      showCodeInput: false,
      code: "",
      countries: countries,
      customer_phone_number: "",
      customer_address: "",
      customer_city: "",
      customer_country: "",
      customer_state: "",
      customer_zip_code: "",
      isCreditCardPayment: false,
      customer_name: "",
      customer_surname: "",
      customer_email: "",
      amount: "",
      comments: "",
      commentPlaceholder:
        "You can enter what you wish to say for instance your informations in oder to thanks you if you whish",
      currency: "",
    };
  },
  methods: {
  async  makeDonation() {
      this.loading = true;
       await this.$http
        .post("/payment/make-donation", {
          customer_phone_number: this.customer_phone_number,
          customer_address: this.customer_address,
          customer_city: this.customer_city,
          customer_country: this.customer_country,
          customer_state: this.customer_state,
          customer_zip_code: this.customer_zip_code,
          customer_name: this.customer_name,
          customer_surname: this.customer_surname,
          customer_email: this.customer_email,
          amount: this.amount,
          comments: this.comments,
          commentPlaceholder: this.commentPlaceholder,
          currency: this.currency,
        })
        .then((response) => {
          this.$toast.success("Donation made success!");
          this.loading = false;
          console.log("response ", response);

          location.assign(response.data.paymentUrl);
        })
        .catch((error) => {
          this.$toast.error(
             "Oups !<br/> Erreur interne chez notre partenaire financier.<br/>SVP contactez-nous sur WhatsApp/Telegram/Email pour assistance rapide. Merci",
            {
              position: "top",
              duration: 7000,
              maxToasts: 2
            }
          );
          this.loading = false;
          console.log("Erreur: ", error);
        });
    },

    numberFormat(n) {
      return numeral(n).format("0,0");
    },

    onPaymentMethodChange() {
      this.isCreditCardPayment = !this.isCreditCardPayment;
    },
  },
  beforeMount() {},

  computed: {
    getPhoneClass() {
      if (this.customer_phone_number.length == 0) return "";
      if (this.customer_phone_number.length < 7) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    getAdressClass() {
      if (this.customer_address.length == 0) return "";
      if (this.customer_address.length < 4) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    getSurnameClass() {
      if (this.customer_surname.length == 0) return "";
      if (this.customer_surname.length < 2) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    getAmountClass() {
      if (this.amount.length == 0) return "";
      var theAmount = parseFloat(this.amount);
      if (theAmount < 100.0 || theAmount > 1500000) return "is-invalid";
      return "";
    },
    getNameClass() {
      if (this.customer_name.length == 0) return "";
      if (this.customer_name.length < 2) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    getCityClass() {
      if (this.customer_city.length == 0) return "";
      if (this.customer_city.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    getEmailClass() {
      if (this.customer_email.length == 0) return "";

      if (CreateStore.state.emailRegex.test(this.customer_email))
        return "is-valid";
      return "is-invalid";
    },

    getStateClass() {
      if (this.customer_state.length == 0) return "";
      if (this.customer_state.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    getZipClass() {
      if (this.customer_zip_code.length == 0) return "";
      if (this.customer_zip_code.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    isSendable() {
      var theAmount = parseFloat(this.amount);
      console.log("Amount", theAmount);
      var t =
        this.customer_phone_number == "" ||
        this.currency == "" ||
        theAmount < 100.0;
      console.log("value", t);
      console.log("Currency", this.currency);
      return (
        (this.isCreditCardPayment == true &&
          (this.customer_phone_number == "" ||
            this.customer_address == "" ||
            this.customer_city == "" ||
            this.customer_country == "" ||
            this.customer_state == "" ||
            this.customer_zip_code == "" ||
            this.currency == "" ||
            !this.$store.state.emailRegex.test(this.customer_email) ||
            theAmount < 100.0)) ||
        (this.isCreditCardPayment == false && t)
      );
    },
  },
};
</script>
<style scoped>
.b-0 {
  position: absolute;
  bottom: 0;
}
.code-input {
  width: 100px;
  border-radius: 4px;
}
.text-through {
  text-decoration: line-through;
}

@media only screen and (max-width: 750px) {
  .footer {
    margin-top: auto;
    top: 5vh !important;
  }
}

.donation-image {
  height: 100%;
}
</style>
